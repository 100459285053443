import React from 'react'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import { DateTime } from 'luxon'
import cn from '../locale/cn'

const PaymentItem = (payment: Payment) => {
  const { paymentId, description, displayStatus } = payment
  const { billingAmount, updatedAt } = payment

  const colors: { [status: string]: string } = {
    confirmed: 'text-success',
    partial_confirmed: 'text-success',
    canceled: 'text-primary'
  }

  return (
    <Link
      to={`/receipt/${paymentId}`}
      className="d-block text-dark text-decoration-none payment-item"
    >
      <article className="card">
        <section className="card-body">
          <p className="card-text">
            <div className="d-flex justify-content-between text-right payment-title">
              <span className="desc">{description}</span>
              <span className="amount">
                {numeral(billingAmount).format()} won
              </span>
            </div>
            <small className="d-flex justify-content-between text-right">
              <span className="text-muted font-weight-bold">
                {DateTime.fromISO(updatedAt).toFormat('HH:mm')}
              </span>
              <span className={colors[displayStatus]}>{cn(displayStatus)}</span>
            </small>
          </p>
        </section>
      </article>
    </Link>
  )
}

export default PaymentItem
