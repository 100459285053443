import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import cn from '../locale/cn'

const List = [
  { to: '/', label: cn('내포인트') },
  { to: '/history', label: cn('결제내역') }
]

const Tabs: React.FC = () => (
  <nav className="nav nav-tabs mb-3">
    {List.map(({ to, label }) => (
      <li className="nav-item" key={label}>
        <Link exact to={to} className="nav-link" activeClassName="active">
          {label}
        </Link>
      </li>
    ))}
  </nav>
)

export default Tabs
