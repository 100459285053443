import React, { useState, createContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ReactComponent as CHAI } from '../images/CHAI.svg'
import AuthRoute from './AuthRoute'
import Tabs from './Tabs'
import Summary from './Summary'
import History from './History'
import Receipt from './Receipt'
import SignIn from './SignIn'
import Disconnected from './Disconnected'

const InitialPayment = { responseList: [], page: 0, hasMore: true }

export const AppContext = createContext<{
  auth: {
    authenticated: boolean
    signIn: () => void
  }
  app: {
    paymentsList: MonthlyPaymentsList
    getPayments: (month: Month) => Payments
    appendPayments: (month: Month, payments: Partial<Payments>) => void
  }
}>({
  auth: {
    authenticated: false,
    signIn: () => {}
  },
  app: {
    paymentsList: {},
    getPayments: () => InitialPayment,
    appendPayments: () => {}
  }
})

const App: React.FC = () => {
  /* auth */
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const auth = { authenticated, signIn: () => setAuthenticated(true) }

  /* payment */
  const [paymentsList, setPaymentsList] = useState<MonthlyPaymentsList>({})

  const getPayments = (month: Month): Payments =>
    paymentsList[month] || InitialPayment

  const appendPayments = (month: Month, payments: Partial<Payments>) =>
    setPaymentsList({
      ...paymentsList,
      [month]: { ...getPayments(month), ...payments }
    })

  const app = { paymentsList, getPayments, appendPayments }

  return (
    <AppContext.Provider value={{ auth, app }}>
      <Disconnected />
      <div className="container">
        <header className="d-flex justify-content-left align-items-center pt-4 pb-5">
          <CHAI width={112} height={20} />
        </header>

        {authenticated && <Tabs />}

        <Switch>
          <AuthRoute path="/history" component={History} />
          <AuthRoute path="/receipt/:id" component={Receipt} />
          <Route exact path="/" component={authenticated ? Summary : SignIn} />
          <Redirect to="/" />
        </Switch>
      </div>
    </AppContext.Provider>
  )
}

export default App
