import React, { useState, useEffect } from 'react'

const Disconnected: React.FC = () => {
  const [connected, setConnected] = useState(true)
  const onOffline = () => setConnected(false)
  const onOnline = () => setConnected(true)

  useEffect(() => {
    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)

    return () => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  return connected ? null : (
    <article className="fixed-top bg-dark text-white p-3">
      <h4 className="">No internet connection</h4>
      <p className="mb-0">
        Please check your internet connection and retry again.
      </p>
    </article>
  )
}

export default Disconnected
