import React, { useState } from 'react'
import api from '../api'
import { getErrorMesage } from '../utils/error'
import { useApp } from '../hooks'
import cn from '../locale/cn'

const SignIn: React.FC = () => {
  const { auth } = useApp()
  const [values, setValues] = useState<SignIn>({ id: '', password: '' })
  const { id, password } = values
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage('')
    setIsSubmitting(true)

    try {
      await api.signin(values)
      setIsSubmitting(false)
      auth.signIn()
    } catch (error) {
      const message = getErrorMesage(error)
      setIsSubmitting(false)
      setErrorMessage(message)
    }
  }

  const spinner = (
    <>
      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
      <span className="sr-only">Loading...</span>
    </>
  )

  return (
    <form onSubmit={submit}>
      <div className="form-group mb-4">
        <label htmlFor="id">ID</label>
        <input
          type="text"
          id="id"
          name="id"
          value={id}
          onChange={handleChange}
          placeholder={cn('아이디')}
          className="form-control form-control-lg"
        />
      </div>

      <div className="form-group mb-5">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={handleChange}
          placeholder={cn('비밀번호')}
          className="form-control form-control-lg"
        />
      </div>

      <button
        type="submit"
        disabled={!id || !password || isSubmitting}
        className="btn btn-primary btn-block btn-lg"
      >
        {!isSubmitting ? 'Log in' : spinner}
      </button>

      {errorMessage && (
        <div className="alert alert-primary mt-4">{errorMessage}</div>
      )}
    </form>
  )
}

export default SignIn
