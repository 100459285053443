import axios, { AxiosPromise } from 'axios'

const HOSTS: { [env: string]: string } = {
  development: 'api-dev.chai.finance',
  staging: 'api-staging.chai.finance',
  production: 'api.chai.finance'
}

const HOST: string = HOSTS[process.env.REACT_APP_ENV || 'development']
const VERSION = 'v1'
const TIMEOUT = 15000

const instance = axios.create({
  baseURL: `https://${HOST}/${VERSION}`,
  timeout: TIMEOUT,
  headers: { 'Accept-Language': 'zh-CN' },
  withCredentials: true
})

export default {
  signin: ({ id, password }: SignIn) => {
    const headers = { 'user-id': id, password: password }
    return instance.post('/df/user/login', null, { headers })
  },
  summary: (): AxiosPromise<Summary> => instance('/df/user/summary'),
  payment: (params: PaymentParams): AxiosPromise<Payment[]> =>
    instance('/df/user/payment', { params })
}
