import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import numeral from 'numeral'
import { DateTime } from 'luxon'
import cn from '../locale/cn'
import { useApp } from '../hooks'

const FMT = 'yyyy/MM/dd HH:mm'
const Receipt: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { app } = useApp()

  const receipt = Object.values(app.paymentsList)
    .map(({ responseList }) => responseList.reduce((p, n) => [...p, ...n], []))
    .reduce((p, n) => [...p, ...n], [])
    .find(({ paymentId }) => match.params.id === paymentId)

  const render = (p: Payment) => {
    const initial = [
      [cn('결제정보'), DateTime.fromISO(p.createdAt).toFormat(FMT), 'title'],
      [cn('결제금액'), `${numeral(p.initial.billingAmount).format()} won`]
    ]

    const canceled = [
      [cn('취소정보'), DateTime.fromISO(p.updatedAt).toFormat(FMT), 'title'],
      [cn('취소금액'), `${numeral(p.canceledBillingAmount).format()} won`]
    ]

    const sum = [[cn('총결제금액'), `${numeral(p.billingAmount).format()} won`]]

    const body: string[][] = initial
      .concat(p.canceledBillingAmount ? canceled : [])
      .concat(sum)

    return (
      <article>
        <h5 className="pt-3 pb-2 mb-0">{p.description}</h5>
        <table className="table table-borderless table-sm receipt-table">
          <tbody>
            {body.map(([th, td, className = '']) => (
              <tr key={th} className={className}>
                <th>{th}</th>
                <td className="text-right">{td}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </article>
    )
  }

  return receipt ? render(receipt) : <Redirect to="/history" />
}

export default Receipt
