import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DateTime } from 'luxon'
import PaymentList from './PaymentList'

const FMT = 'yyyy-MM'
const INITIAL = '2019-01'
const History: React.FC<RouteComponentProps> = () => {
  const today = DateTime.local().toFormat(FMT)
  const [month, setMonth] = useState<Month>(today)

  const months = getMonths([INITIAL, today])
  return (
    <>
      <div className="pt-2 pb-3">
        <select
          value={month}
          onChange={e => setMonth(e.target.value)}
          className="custom-select"
        >
          {months.map(month => (
            <option value={month} key={month}>
              {DateTime.fromFormat(month, FMT).toFormat('yyyy.MM')}
            </option>
          ))}
        </select>
      </div>

      <PaymentList month={month} />
    </>
  )
}

export default History

/* helpers */
const getMonths = ([start, end]: Month[]): Month[] => {
  const reducer = (acc: Month[]): Month[] => {
    const [cur] = acc
    const nextMonth = getNextMonth(cur)
    return DateTime.fromFormat(nextMonth, FMT) <= DateTime.fromFormat(end, FMT)
      ? reducer([nextMonth, ...acc])
      : acc
  }

  return reducer([start])
}

const getNextMonth = (month: Month): Month =>
  DateTime.fromFormat(month, FMT)
    .plus({ months: 1 })
    .toFormat(FMT)
