import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import numeral from 'numeral'
import classNames from 'classnames'
import cn from '../locale/cn'
import api from '../api'

const Summary: React.FC<RouteComponentProps> = () => {
  const [summary, setSummary] = useState<Summary>()
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    setErrorMessage('')

    const fetch = async () => {
      try {
        const { data } = await api.summary()
        setSummary(data)
      } catch (error) {
        setErrorMessage(error)
      }
    }

    fetch()
  }, [])

  const getContents = (summary: Summary) => [
    {
      title: cn('잔액'),
      text: numeral(summary.balance).format(),
      badge: summary.status === 'active' && cn(summary.status)
    },
    {
      title: cn('입금코드'),
      text: summary.depositCode,
      well: true
    }
  ]

  const isInactive = summary && summary.status === 'inactive'
  const inactiveMessages = ['계정에문제가생겼습니다', '고객센터로문의해주세요']

  return errorMessage ? (
    <div className="alert alert-primary mb-0">{errorMessage}</div>
  ) : !summary ? null : (
    <>
      {isInactive && (
        <div className="alert alert-primary mb-0">
          {inactiveMessages.map(cn).join(' ')}
        </div>
      )}

      {getContents(summary).map(({ title, text, badge, well }) => (
        <article className="card" key={title}>
          <section className="card-body">
            <h5 className="card-title text-dark font-weight-normal">
              {title}
              {badge && (
                <span className="badge badge-primary ml-2 font-weight-bold">
                  {badge}
                </span>
              )}
            </h5>
            <h3
              className={classNames(
                'card-text text-dark font-weight-normal',
                isInactive && 'text-muted',
                !!well && 'code'
              )}
            >
              {text}
            </h3>
          </section>
        </article>
      ))}

      <div className="note">
        <p className="note-title">{cn('유의사항')}</p>
        <pre>{cn('유의사항내용')}</pre>
      </div>
    </>
  )
}

export default Summary
