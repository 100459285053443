import React, { useState, useEffect } from 'react'
import { groupBy, sortBy, head, reverse } from 'ramda'
import { DateTime } from 'luxon'
import 'intersection-observer'
import InView from 'react-intersection-observer'
import cn from '../locale/cn'
import api from '../api'
import { useApp } from '../hooks'
import PaymentItem from './PaymentItem'

const PAGE_SIZE = 20
const FMT = 'yyyyMMdd'

const PaymentList: React.FC<{ month: Month }> = ({ month }) => {
  const { app } = useApp()
  const { responseList, page, hasMore } = app.getPayments(month)
  const loadMonth = () => app.appendPayments(month, { page: page + 1 })

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    const fetch = async () => {
      setErrorMessage('')
      setIsLoading(true)

      try {
        const { data } = await api.payment({ month, page, pageSize: PAGE_SIZE })
        const next = {
          responseList: [...responseList, data],
          hasMore: !!data.length
        }
        app.appendPayments(month, next)
      } catch (error) {
        setErrorMessage(error)
      }

      setIsLoading(false)
    }

    !responseList[page] && fetch()
    // eslint-disable-next-line
  }, [month, page])

  const list = responseList.reduce((p, n) => [...p, ...n], [])
  return errorMessage ? (
    <p className="text-danger">{errorMessage}</p>
  ) : !list.length ? (
    isLoading ? null : (
      <p className="text-muted">{cn('검색결과가없습니다')}</p>
    )
  ) : (
    <>
      {group(list).map(([date, list]) => (
        <article className="mb-4" key={date}>
          <h6 className="payment-date">
            {DateTime.fromFormat(date, FMT).toFormat('MM.dd')}
          </h6>
          {list.map((item, index) => (
            <PaymentItem {...item} key={index} />
          ))}
        </article>
      ))}

      {hasMore && (
        <InView onChange={inView => inView && loadMonth()}>
          {({ ref }) => (
            <div ref={ref} className="text-center mb-4">
              {isLoading ? (
                <div className="loading">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  onClick={loadMonth}
                  className="btn btn-light btn-block btn-lg"
                >
                  {cn('더불러오기')}
                </button>
              )}
            </div>
          )}
        </InView>
      )}
    </>
  )
}

export default PaymentList

/* utils */
const group = (list: Payment[]): [string, Payment[]][] => {
  const sortByHead = sortBy(head)
  const byDate = groupBy<Payment>(({ updatedAt }) =>
    DateTime.fromISO(updatedAt).toFormat(FMT)
  )

  return reverse(sortByHead(Object.entries(byDate(list))))
}
