const Chinese: { [key: string]: string } = {
  /* Forms */
  아이디: '用户名',
  비밀번호: '密码',

  /* Tabs */
  내포인트: '我的积分',
  결제내역: '支付记录',

  /* Summary */
  계정에문제가생겼습니다: '账户异常',
  고객센터로문의해주세요: '请咨询客服',
  잔액: '余额',
  입금코드: '存款码',
  유의사항: `注意事项`,
  유의사항내용: `· 银行转账时，请输入正确的存款码
· 1积分等于1韩元
· 如果输入了错误的存款码，请及时联系客服
· 转账的金额转换成积分最多可能需要3个工作日`,

  /* Summary - status */
  active: '已激活',

  /* Payment list */
  검색결과가없습니다: '找不到相应结果',
  더불러오기: '加载更多',

  /* Receipt */
  결제정보: '支付信息',
  결제금액: '支付金额',
  취소정보: '取消信息',
  취소금액: '取消金额',
  총결제금액: '总支付金额',

  /* Receipt - status */
  confirmed: '支付',
  canceled: '取消支付',
  partial_confirmed: '部分取消'
}

export default (s: string): string =>
  (process.env.REACT_APP_LANG !== 'ko' && Chinese[s]) || s
