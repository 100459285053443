import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useApp } from '../hooks'

const AuthRoute: React.FC<RouteProps> = props => {
  const { auth } = useApp()
  return auth.authenticated ? <Route {...props} /> : <Redirect to="/" />
}

export default AuthRoute
